import { createSlice } from '@reduxjs/toolkit'


interface ratingState {
    editRating: {
      _id: string,
      rating: number,
      review: string | null
    }
}


const initialState: ratingState = {
    editRating: {
      _id: '',
      rating: 0,
      review: null
    }
}


export const ratingSlice = createSlice({
    name: 'rating',
    initialState,
    reducers: {
        ratingEdit: (state, {payload}) => {
            state.editRating = payload
          },
        clearRatingEdit: (state) => {
            state.editRating = {
              _id: '',
              rating: 0,
              review: null
            }
          },
    },
})

export const { ratingEdit, clearRatingEdit } = ratingSlice.actions;

export default ratingSlice.reducer
