import axios from 'axios';

export const LocationAPI = async () => {
    const url = `https://ip-api.io/json?api_key=${process.env.REACT_APP_LOCATION_KEY}`
    try {
      return await axios({
        method: 'GET',
        url: url,
      })
        .then(res => {
          return res.data
        }
        )
    } catch (error: any) {
      console.log(error.message)
  }
}