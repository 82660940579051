import axios from 'axios';


/** Create post api */

export interface CreatePostProps {
  post_type: string,
  title: string,
  content: string,
  from_city: string,
  from_state: string,
  from_country: string,
  destination_city: string,
  destination_state: string,
  destination_country: string,
  accept_crypto: boolean | null,
  show_phone_number: boolean | null,
  phone_calls: boolean | null,
  text_sms: boolean | null,
  package_size_dimension: string | null,
  vehicle_type: string | null,
  available_seats: number | null,
  price_min: number | null,
  price_max: number | null,
  currency: string | null,
  show_price: boolean | null,
  date_departure: Date | null,
  expired: boolean | null,
  package_image: string | File,
  vehicle_image: string | File,
  status: string | null,
  _id: string | null,
}


// export const CreatePostAPI = async ({
//   post_type,
//   title,
//   content,
//   from_city,
//   from_state,
//   from_country,
//   destination_city,
//   destination_state,
//   destination_country,
//   accept_crypto,
//   show_phone_number,
//   phone_calls,
//   text_sms,
//   package_size_dimension,
//   vehicle_type,
//   available_seats,
//   price_min,
//   price_max,
//   currency,
//   show_price,
//   date_departure,
//   expired,
//   package_image,
//   vehicle_image,
//   status, }: CreatePostProps) => {

    // const data = {
    //   post_type,
    //   title,
    //   content,
    //   from_city,
    //   from_state,
    //   from_country,
    //   destination_city,
    //   destination_state,
    //   destination_country,
    //   accept_crypto,
    //   show_phone_number,
    //   phone_calls,
    //   text_sms,
    //   package_size_dimension,
    //   vehicle_type,
    //   available_seats,
    //   price_min,
    //   price_max,
    //   currency,
    //   show_price,
    //   date_departure,
    //   expired,
    //   package_image,
    //   vehicle_image,
    //   status,
    // }

// export const CreatePostAPI = async ( data: CreatePostProps) => {
//   const url = `${process.env.REACT_APP_BASE_URL}/post/create`
//   const token = localStorage.getItem('token')
//   if (!token) return { status: 'Failed', message: 'Token not found'}
//
//   const config: AxiosRequestConfig = {
//       method: 'POST',
//       url: url,
//       headers: {
//         'Content-Type': 'application/json',
//         'x-access-token': token
//       },
//       data: data
//     };
//
//   try {
//     const response: AxiosResponse = await axios(config);
//     return response;
//   } catch(err) {
//     console.log(err);
//   };
// }

export const CreatePostAPI = async ( data: CreatePostProps) => {
    //console.log("Post data", data);
    const url = `${process.env.REACT_APP_BASE_URL}/post/create`
    const token = localStorage.getItem('token')
    const formData = new FormData();
    formData.append("post_type", data.post_type)
    formData.append("title", data.title)
    formData.append("content", data.content)
    formData.append("from_city", data.from_city)
    formData.append("from_state", data.from_state)
    formData.append("from_country", data.from_country)
    formData.append("destination_city", data.destination_city)
    formData.append("destination_state", data.destination_state)
    formData.append("destination_country", data.destination_country)
    formData.append("accept_crypto", JSON.stringify(data.accept_crypto))
    formData.append("package_size_dimension", data.package_size_dimension!.toString())
    formData.append("vehicle_type", data.vehicle_type!.toString())
    formData.append("available_seats", data.available_seats!.toString())
    formData.append("price_min", data.price_min!.toString())
    formData.append("price_max", data.price_max!.toString())
    formData.append("currency", data.currency!.toString())
    if (data.date_departure) formData.append("date_departure", data.date_departure!.toString())
    else formData.append("date_departure", '')
    formData.append("expired", JSON.stringify(data.expired))
    formData.append("package_image", data.package_image)
    formData.append("vehicle_image", data.vehicle_image)
    formData.append("status", data.status!.toString())
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'Accept': "multipart/form-data",
          'x-access-token': token
        },
        data: formData
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("create post axios request", err.request)
            } else {
              console.log("create post axios error something else", err.request)
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("create post response error catch: ", error.response)
      }
    }
}



export const GetSinglePostAPI = async ( id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/post/getposting?postId=${id}`
    //const token = localStorage.getItem('token')
    //if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          //'x-access-token': token
        },
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("get post axios request", err.request)
            } else {
              console.log("get post axios error something else", err.request)
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("get post response error catch: ", error.response)
      }
    }
}

export const EditPostAPI = async ( data: CreatePostProps) => {
  //console.log("Post data Edit test: ", data);
  const url = `${process.env.REACT_APP_BASE_URL}/post/edit`
  const token = localStorage.getItem('token')
  const formData = new FormData();
  formData.append("post_type", data.post_type)
  formData.append("title", data.title)
  formData.append("content", data.content)
  formData.append("from_city", data.from_city)
  formData.append("from_state", data.from_state)
  formData.append("from_country", data.from_country)
  formData.append("destination_city", data.destination_city)
  formData.append("destination_state", data.destination_state)
  formData.append("destination_country", data.destination_country)
  formData.append("accept_crypto", JSON.stringify(data.accept_crypto))
  formData.append("package_size_dimension", data.package_size_dimension!.toString())
  formData.append("vehicle_type", data.vehicle_type!.toString())
  formData.append("available_seats", data.available_seats!.toString())
  if (data.date_departure) formData.append("date_departure", data.date_departure!.toString())
  else formData.append("date_departure", '')
  formData.append("price_min", data.price_min!.toString())
  formData.append("price_max", data.price_max!.toString())
  formData.append("currency", data.currency!.toString())
  formData.append("expired", JSON.stringify(data.expired))
  formData.append("package_image", data.package_image)
  formData.append("vehicle_image", data.vehicle_image)
  formData.append("status", data.status!.toString())
  formData.append("postId", data._id!.toString())
  //console.log("form data: ", formData)
  if (!token) return { status: 'Failed', message: 'Token not found'}

  try {
    //console.log("called for post user")
    return axios({
      method: 'PUT',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Accept': "multipart/form-data",
        'x-access-token': token
      },
      data: formData
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("create post axios request", err.request)
          } else {
            console.log("create post axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("create post response error catch: ", error.response)
    }
  }
}

export const DeletePostAPI = async (id: string) => {
  const url = `${process.env.REACT_APP_BASE_URL}/post/delete`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'DELETE',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: {postingId: id}
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("delete post axios request", err.request)
          } else {
            console.log("delete post axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("delete post response error catch: ", error.response)
    }
  }
}
