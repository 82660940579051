import axios from 'axios';
import imageProcessor from '../utils/imageProcessor'
import urlToFile from '../utils/urlToFile';

/** Create blog api */

export interface RatingProps {
  rating: number,
  review: string | null,
  reviewerId: string,
  user: string,
}

export interface ImageProps {
  file: string
}

function uploadFile(data: any) {

  const url = `${process.env.REACT_APP_BASE_URL}/upload`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}

  try {
    return axios({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'Accept': "multipart/form-data",
        'x-access-token': token
      },
      data: data
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("upload image axios request", err.request)
          } else {
            console.log("upload image axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("upload image response error catch: ", error.response)
    }
  }
}


export const UploadImageAPI = async ( file: any) => {

  return new Promise(async(resolve, reject) => {
    const imagePackage = await imageProcessor(file)
    urlToFile(imagePackage, 'blog.png')
     .then(async (file)=>{
         const form_data = new FormData();
         form_data.append("file", file);
         form_data.append("category", "BLOG")
         const res = await uploadFile(form_data);
         resolve({ data: { link: res.data.url } });
     });


    // const reader = new window.FileReader();
    // reader.onloadend = async () => {
    //   const form_data = new FormData();
    //   form_data.append("file", file);
    //   form_data.append("category", "BLOG")
    //   const res = await uploadFile(form_data);
    //
    //   resolve({ data: { link: res.data.url } });
    // };
  });
}


export interface BlogProps {
  title: string | null,
  content: string | null
}

export const CreateBlogAPI = async ( data: BlogProps) => {
  console.log("data: ", data)
  const url = `${process.env.REACT_APP_BASE_URL}/blog/create`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: data
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            console.log("create blog error response: ", err.response)
            return err.response
          } else if (err.request) {
            console.log("create blog axios request", err.request)
          } else {
            console.log("create blog axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("create blog response error catch: ", error.response)
    }
  }
}


export interface EditBlogProps {
  title: string | null,
  content: string | null,
  blogId: string | null
}

export const EditBlogAPI = async ( data: EditBlogProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/blog/edit`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: data
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("edit blog axios request", err.request)
          } else {
            console.log("edit blog axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("edit blog response error catch: ", error.response)
    }
  }
}

export const DeleteBlogAPI = async ( blogId: string) => {
  const url = `${process.env.REACT_APP_BASE_URL}/blog/delete`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: {blogId}
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("delete blog axios request", err.request)
          } else {
            console.log("delete blog axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("delete blog response error catch: ", error.response)
    }
  }
}



export const GetBlogBySlugAPI = async ( slug: string) => {

  if (!slug) return {status: 500, error: "Slug is required", blog: {}}
  const url = `${process.env.REACT_APP_BASE_URL}/blog/${slug}`

  try {
    return axios({
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("GET blog axios request", err.request)
          } else {
            console.log("GEET blog axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("GET blog response error catch: ", error.response)
    }
  }
}


export const GetBlogsAPI = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/blog/all`

  try {
    return axios({
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("GET all blog axios request", err.request)
          } else {
            console.log("GEET all blog axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("GET all blog response error catch: ", error.response)
    }
  }
}
