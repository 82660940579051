import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ReportPostAPI } from './../api/report'

interface reportState {
    isLogSuccess: boolean,
    isLogFetching: boolean,
    errorLogMessage: string | null
}

interface ValidationErrors {
    errorLogMessage: string | null
}

const initialState: reportState = {
    isLogSuccess: false,
    isLogFetching: false,
    errorLogMessage: ""
}

export const reportPost = createAsyncThunk<
    void,
    { postId: string, reporterId: string, reason: string},
    { rejectValue: ValidationErrors }
>(
    'user/login',
    async (formData, thunkAPI) => {
        const response = await ReportPostAPI(formData)
        if (response.status !== 200) {
            if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
            else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data
    }
)

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReportState: (state) => {
            state.errorLogMessage = '';
            state.isLogSuccess = false;
            state.isLogFetching = false;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(reportPost.fulfilled, (state, {payload}) => {
          //console.log("report post fulfilled: ", payload)
            state.isLogFetching = false
            state.isLogSuccess = true
        })
        builder.addCase(reportPost.rejected, (state, action) => {
            state.isLogFetching = false
            if (action.payload) {
                state.errorLogMessage = action.payload as unknown as string
              } else {
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(reportPost.pending, (state) => {
            state.isLogFetching = true
        })
    },
})

export const { clearReportState } = reportSlice.actions;

export default reportSlice.reducer
