import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {GetUserProfileAPI} from './../api/user'

interface profileProps {
  _id: string,
  user: string,
  first_name: string | null,
  last_name: string | null,
  phone_number: string | null,
  profile_pic: string | null,
  street: string | null,
  city: string | null,
  district: string | null,
  state: string | null,
  country: string | null,
  latitude: string | null,
  longtitude: string | null,
}

interface profileState {
  isGetSuccess: boolean,
  isGetFetching: boolean,
  errorLogMessage: string | null,
  profileDetails: {
    aveRating: string,
    profile: profileProps,
    ratings: [] | [
      {
        createdAt: Date,
        rating: number,
        review: string | null,
        reviewer: profileProps,
        updatedAt: Date,
        user: string,
        _id: string,
      }
    ]
  }
}


const initialState: profileState = {
  isGetSuccess: false,
  isGetFetching: false,
  errorLogMessage: "",
  profileDetails: {
    aveRating: "0.00",
    profile: {
      _id: "",
      user: "",
      first_name: null,
      last_name: null,
      phone_number: null,
      profile_pic: null,
      street: null,
      city: null,
      district: null,
      state: null,
      country: null,
      latitude: null,
      longtitude: null,
    },
    ratings: []
  }
}


export const getProfile = createAsyncThunk(
    'profile/getter',
    async (profileId: string, thunkAPI) => {
        const response = await GetUserProfileAPI(profileId)
        console.log("Response", response.data)
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data
    }
)


export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // profileDetails: (state, {payload}) => {
        //     state.profileDetails = payload
        //   },
        clearProfileDetails: (state) => {
            state.profileDetails = {
              aveRating: "0.00",
              profile: {
                _id: "",
                user: "",
                first_name: null,
                last_name: null,
                phone_number: null,
                profile_pic: null,
                street: null,
                city: null,
                district: null,
                state: null,
                country: null,
                latitude: null,
                longtitude: null,
              },
              ratings: []
            }
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, {payload}) => {
            state.isGetFetching = false
            state.isGetSuccess = true
            state.profileDetails = payload
            console.log("fulfiled: ", payload)
        })
        builder.addCase(getProfile.rejected, (state, action) => {
            console.log("action: ", action)
            if (action.payload) {
                console.log("error message payload: ", action.payload)
                state.errorLogMessage = action.payload as unknown as string
              } else {
                console.log("error message error: ", action.error.message)
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(getProfile.pending, (state) => {
            state.isGetFetching = true
        })
    },
})

export const { clearProfileDetails } = profileSlice.actions;

export default profileSlice.reducer
