import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetAdminDashboardAPI,
  ChangeUserRole,
  ReactivateUserRole,
  DeleteUser,
  DeletePost
} from '../api/admin';

interface adminState {
    isGetIndexSuccess: boolean,
    isGetIndexFetching: boolean,
    errorLogMessage: string | null
    isChangeRoleSuccess: boolean,
    isChangeRoleRequesting: boolean,
    errorChangeRoleMessage: string | null
    isReactivateSuccess: boolean,
    isReactivateRequesting: boolean,
    errorReactivateMessage: string | null,
    isDeleteSuccess: boolean,
    isDeleteRequesting: boolean,
    errorDeleteMessage: string | null,
    isDeletePostsSuccess: boolean,
    isDeletePostsRequesting: boolean,
    errorDeletePostsMessage: string | null,
    adminDashboard: {
        users: [],
        dailySignup: [],
        posts: [],
        dailyPosts: [],
        reviews: [],
        reports: [],
        dailyReports: []
      }
}

const initialState: adminState = {
    isGetIndexSuccess: false,
    isGetIndexFetching: false,
    errorLogMessage: "",
    isChangeRoleSuccess: false,
    isChangeRoleRequesting: false,
    errorChangeRoleMessage: "",
    isReactivateSuccess: false,
    isReactivateRequesting: false,
    errorReactivateMessage: "",
    isDeleteSuccess: false,
    isDeleteRequesting: false,
    errorDeleteMessage: "",
    isDeletePostsSuccess: false,
    isDeletePostsRequesting: false,
    errorDeletePostsMessage: "",
    adminDashboard: {
        users: [],
        dailySignup: [],
        posts: [],
        dailyPosts: [],
        reviews: [],
        reports: [],
        dailyReports: []
      }
}


export const getAdminDashboard = createAsyncThunk(
    'admin/index',
    async (dash: string, thunkAPI) => {
        const response = await GetAdminDashboardAPI()
        //console.log("admin dashboard: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)

interface ChangeRoleValidationErrors {
    errorChangeRoleMessage: string | null
}

export const adminChangeUserRole = createAsyncThunk<
      any,
      { profileId: string, newRole: string},
      { rejectValue: ChangeRoleValidationErrors }
    >(
    'admin/changeUserRole',
    async (formData, thunkAPI) => {
        const response = await ChangeUserRole(formData)
        //console.log("admin dashboard: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)

interface ReactivateValidationErrors {
    errorReactivateMessage: string | null
}

export const adminReactivateUser = createAsyncThunk<
      any,
      { userid: string,},
      { rejectValue: ReactivateValidationErrors }
    >(
    'admin/reactivateUser',
    async (formData, thunkAPI) => {
        const response = await ReactivateUserRole(formData)
        //console.log("admin dashboard: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)


interface DeleteValidationErrors {
    errorDeleteMessage: string | null
}

export const adminDeleteUser = createAsyncThunk<
      any,
      { userid: string,},
      { rejectValue: DeleteValidationErrors }
    >(
    'admin/delete',
    async (formData, thunkAPI) => {
        const response = await DeleteUser(formData)
        //console.log("admin dashboard delete user: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)

interface DeletePostsErrors {
    errorDeletePostsMessage: string | null
}

export const adminDeletePosts = createAsyncThunk<
      any,
      { postIds: String[],},
      { rejectValue: DeletePostsErrors }
    >(
    'admin/deletePost',
    async (formData, thunkAPI) => {
        const response = await DeletePost(formData)
        //console.log("admin dashboard delete post: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        clearAdminDashboard: (state) => {
            state.isGetIndexSuccess = false;
            state.isGetIndexFetching = false;
            state.errorLogMessage = "";
            state.isChangeRoleSuccess = false;
            state.isChangeRoleRequesting = false;
            state.errorChangeRoleMessage = "";
            state.isReactivateSuccess = false;
            state.isReactivateRequesting = false;
            state.errorReactivateMessage = "";
            state.isDeleteSuccess = false;
            state.isDeleteRequesting = false;
            state.errorDeleteMessage = "";
            state.isDeletePostsSuccess = false;
            state.isDeletePostsRequesting = false;
            state.errorDeletePostsMessage = "";
            state.adminDashboard = {
                users: [],
                dailySignup: [],
                posts: [],
                dailyPosts: [],
                reviews: [],
                reports: [],
                dailyReports: []
              };
          },
          clearUserStates : (state) => {
            state.isChangeRoleSuccess = false;
            state.isChangeRoleRequesting = false;
            state.errorChangeRoleMessage = "";
            state.isReactivateSuccess = false;
            state.isReactivateRequesting = false;
            state.errorReactivateMessage = "";
            state.isDeleteSuccess = false;
            state.isDeleteRequesting = false;
            state.errorDeleteMessage = "";
          },
          clearPostStates : (state) => {
            state.isDeletePostsSuccess = false;
            state.isDeletePostsRequesting = false;
            state.errorDeletePostsMessage = "";
          }
    },
    extraReducers: (builder) => {
        builder.addCase(getAdminDashboard.fulfilled, (state, {payload}) => {
            state.isGetIndexFetching = false
            state.isGetIndexSuccess = true
            state.adminDashboard = payload
        })
        builder.addCase(getAdminDashboard.rejected, (state, action) => {

            if (action.payload) {
                state.errorLogMessage = action.payload as unknown as string
              } else {
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(getAdminDashboard.pending, (state) => {
            state.isGetIndexFetching = true
        })

        builder.addCase(adminChangeUserRole.fulfilled, (state, {payload}) => {
            state.isChangeRoleSuccess = true
            state.isChangeRoleRequesting = false
            state.adminDashboard = payload
        })
        builder.addCase(adminChangeUserRole.rejected, (state, action) => {

            if (action.payload) {
                state.errorChangeRoleMessage = action.payload as unknown as string
              } else {
                state.errorChangeRoleMessage = action.error.message!
              }
        })
        builder.addCase(adminChangeUserRole.pending, (state) => {
            state.isChangeRoleRequesting = true
        })

        builder.addCase(adminReactivateUser.fulfilled, (state, {payload}) => {
            state.isReactivateRequesting = false
            state.isReactivateSuccess = true
            state.adminDashboard = payload
        })
        builder.addCase(adminReactivateUser.rejected, (state, action) => {

            if (action.payload) {
                state.errorReactivateMessage = action.payload as unknown as string
              } else {
                state.errorReactivateMessage = action.error.message!
              }
        })
        builder.addCase(adminReactivateUser.pending, (state) => {
            state.isReactivateRequesting = true
        })

        builder.addCase(adminDeleteUser.fulfilled, (state, {payload}) => {
            state.isDeleteRequesting = false
            state.isDeleteSuccess = true
            state.adminDashboard = payload
        })
        builder.addCase(adminDeleteUser.rejected, (state, action) => {

            if (action.payload) {
                state.errorDeleteMessage = action.payload as unknown as string
              } else {
                state.errorDeleteMessage = action.error.message!
              }
        })
        builder.addCase(adminDeleteUser.pending, (state) => {
            state.isDeleteRequesting = true
        })

        builder.addCase(adminDeletePosts.fulfilled, (state, {payload}) => {
            state.isDeletePostsRequesting = false
            state.isDeletePostsSuccess = true
        })
        builder.addCase(adminDeletePosts.rejected, (state, action) => {

            if (action.payload) {
                state.errorDeleteMessage = action.payload as unknown as string
              } else {
                state.errorDeleteMessage = action.error.message!
              }
        })
        builder.addCase(adminDeletePosts.pending, (state) => {
            state.isDeletePostsRequesting = true
        })
    },
})

export const { clearAdminDashboard, clearUserStates, clearPostStates } = adminSlice.actions;

export default adminSlice.reducer
