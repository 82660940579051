import { Suspense } from 'react'
import ReactGA from 'react-ga4';
import GlobalStyle from './globalStyles';
import GlobalFonts from './fonts/fonts';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import lazyWithRetry from "./lazyWithRetry";
import { RootState } from "./app/store";
import Spinner from './components/Spinner'
// import ScrollToTop from "./ScrollToTop";
const ScrollToTop = lazyWithRetry(() => import("./ScrollToTop"))
// import NavBar from './components/NavBar'
const NavBar  = lazyWithRetry(() => import("./components/NavBar"))
// import Home from "./pages/Home"
const Home  = lazyWithRetry(() => import("./pages/Home"))
// import Login from "./pages/Login"
const Login  = lazyWithRetry(() => import("./pages/Login"))
// import Register from "./pages/Register"
const Register  = lazyWithRetry(() => import("./pages/Register"))
// import About from "./pages/About"
const About  = lazyWithRetry(() => import("./pages/About"))
// import EmailSent from "./pages/EmailSent"
const EmailSent  = lazyWithRetry(() => import("./pages/EmailSent"))
// import Verification from './pages/Verification';
const Verification  = lazyWithRetry(() => import("./pages/Verification"))
// import ForgotPassword from './pages/ForgotPassword'
const ForgotPassword  = lazyWithRetry(() => import("./pages/ForgotPassword"))
// import UserDashboard from './pages/UserDashboard';
const UserDashboard  = lazyWithRetry(() => import("./pages/UserDashboard"))
// import CreatePost from './pages/PostCreate';
const CreatePost  = lazyWithRetry(() => import("./pages/PostCreate"))
// import PostDisplaySingle from './pages/PostDisplaySingle';
const PostDisplaySingle  = lazyWithRetry(() => import("./pages/PostDisplaySingle"))
// import UserProfile from './pages/UserProfile';
const UserProfile  = lazyWithRetry(() => import("./pages/UserProfile"))
// import PasswordReset from './pages/PasswordReset';
const PasswordReset  = lazyWithRetry(() => import("./pages/PasswordReset"))
// import ChangePassword from './pages/ChangePassword';
const ChangePassword  = lazyWithRetry(() => import("./pages/ChangePassword"))

const EmailNotification  = lazyWithRetry(() => import("./pages/EmailNotification"))
// import RatingEdit from './pages/RatingEdit';
const RatingEdit  = lazyWithRetry(() => import("./pages/RatingEdit"))
// import ProfileEdit from './pages/ProfileEdit';
const ProfileEdit  = lazyWithRetry(() => import("./pages/ProfileEdit"))
// import DeactivateAccount from './pages/DeactivateAccount';
const DeactivateAccount  = lazyWithRetry(() => import("./pages/DeactivateAccount"))
// import Faqs from './pages/Faqs';
const Faqs  = lazyWithRetry(() => import("./pages/Faqs"))
// import PostServiceList from './pages/PostServiceList';
const PostServiceList  = lazyWithRetry(() => import("./pages/PostServiceList"))
// import EditPostService from './pages/EditPostService';
const EditPostService  = lazyWithRetry(() => import("./pages/EditPostService"))
// import BlogEditCreate from './pages/BlogEditCreate';
const BlogEditCreate  = lazyWithRetry(() => import("./pages/BlogEditCreate"))
// import BlogDetails from './pages/BlogDetails';
const BlogDetails  = lazyWithRetry(() => import("./pages/BlogDetails"))
// import BlogList from './pages/BlogList';
const BlogList  = lazyWithRetry(() => import("./pages/BlogList"))
// import Help from './pages/Help';
const Help  = lazyWithRetry(() => import("./pages/Help"))
// import TermsOfUse from './pages/TermsOfUse';
const TermsOfUse  = lazyWithRetry(() => import("./pages/TermsOfUse"))
// import PrivacyPolicy from './pages/PrivacyPolicy';
const PrivacyPolicy  = lazyWithRetry(() => import("./pages/PrivacyPolicy"))
// import Disclaimer from './pages/Disclaimer';
const Disclaimer  = lazyWithRetry(() => import("./pages/Disclaimer"))

const WalletDownload  = lazyWithRetry(() => import("./pages/WalletDownload"))

// import Admin dashboard index from './pages/AdminDashboardIndex';
const AdminDashboardIndex  = lazyWithRetry(() => import("./pages/AdminDashboardIndex"))
const AdminDashboardUsers  = lazyWithRetry(() => import("./pages/AdminDashboardUsers"))
const AdminDashboardPosts  = lazyWithRetry(() => import("./pages/AdminDashboardPosts"))
const AdminDashboardReports  = lazyWithRetry(() => import("./pages/AdminDashboardReports"))
const AdminHelpVideo  = lazyWithRetry(() => import("./pages/AdminHelpVideo"))

/*
  Initialize React-GA
  TRACKING_ID is the tracking id from google analytics
*/
const TRACKING_ID = process.env.REACT_APP_GA_KEY;
//console.log("ga tracking id: ", TRACKING_ID)
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send("pageview");
}

function App() {
  const token = localStorage.getItem('token')
  const { isAddSuccess } = useSelector((state: RootState) => state.addPassword)
  const { isLogSuccess } = useSelector((state: RootState) => state.login)
  const {dashboard } = useSelector((state: RootState) => state.dashboard)
  const userRole = dashboard.profile.user_role
  //console.log("userRole: ", userRole)

  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <ScrollToTop>
          <GlobalFonts />
          <GlobalStyle />
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ref_fb" element={<Home />} />
            <Route path="/ref_twtr" element={<Home />} />
            <Route path="/ref_insta" element={<Home />} />
            <Route path="/ref_tiktok" element={<Home />} />
            <Route path="/ref_yt" element={<Home />} />
            <Route path="/ref_lnkd" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/emailsent" element={<EmailSent />} />
            <Route path="/setpassword" element={<Verification />} />
            <Route path="/passwordReset" element={<PasswordReset />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/change/password" element={token || isAddSuccess || isLogSuccess ? <ChangePassword /> : <Navigate to='/login' />} />
            <Route path="/userdashboard" element={token || isAddSuccess || isLogSuccess ? <UserDashboard /> : <Navigate to='/login' />} />
            <Route path="/profile/:profileId" element={<UserProfile />} />
            <Route path="/profile/edit" element={token || isAddSuccess || isLogSuccess ? <ProfileEdit /> : <Navigate to='/login' />} />
            <Route path="/account/deactivate" element={token || isAddSuccess || isLogSuccess ? <DeactivateAccount /> : <Navigate to='/login' />} />
            <Route path="/account/email_notification" element={token || isAddSuccess || isLogSuccess ? <EmailNotification /> : <Navigate to='/login' />} />
            <Route path="/createpost" element={token || isAddSuccess || isLogSuccess ? <CreatePost /> : <Navigate to='/login' />} />
            <Route path="/post/:id" element={<PostDisplaySingle />} />
            <Route path="/rating/edit" element={<RatingEdit />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/services/list" element={<PostServiceList />} />
            <Route path="/edit/post" element={token || isAddSuccess || isLogSuccess ? <EditPostService /> : <Navigate to='/login' />} />
            <Route path="/blog/create" element={token && userRole === 'Admin' ? <BlogEditCreate /> : <Navigate to='/login' />} />
            <Route path="/blog/edit" element={token && userRole === 'Admin' ? <BlogEditCreate /> : <Navigate to='/login' />} />
            <Route path="/blog/list" element={<BlogList />} />
            <Route path="/blog/read/:slug" element={<BlogDetails />} />
            <Route path="/help" element={<Help />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/policy" element={<PrivacyPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            {/* <Route path="/wallet_download" element={<WalletDownload />} /> */}
            <Route path="/admin" element={token && userRole === 'Admin' ? <AdminDashboardIndex /> : <Navigate to='/login' />} />
            <Route path="/admin/users" element={token && userRole === 'Admin' ? <AdminDashboardUsers /> : <Navigate to='/login' />} />
            <Route path="/admin/posts" element={token && userRole === 'Admin' ? <AdminDashboardPosts /> : <Navigate to='/login' />} />
            <Route path="/admin/reports" element={token && userRole === 'Admin' ? <AdminDashboardReports /> : <Navigate to='/login' />} />
            <Route path="/admin/video/create" element={token && userRole === 'Admin' ? <AdminHelpVideo /> : <Navigate to='/login' />} />
            <Route path="/admin/video/edit" element={token && userRole === 'Admin' ? <AdminHelpVideo /> : <Navigate to='/login' />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </Suspense>
  );
}

export default App;
