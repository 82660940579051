import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LocationAPI } from '../api/location'

interface cityState {
    location: string,
}


const initialState: cityState = {
    location : ""
}

export const locationFinder = createAsyncThunk(
    'location/finder',
    async (thunkAPI) => {
        const response = await LocationAPI()
        return await response
    }
)

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        updateCity: (state, {payload}) => {
            state.location = payload
          },
    },
    extraReducers: (builder) => {
        builder.addCase(locationFinder.fulfilled, (state, {payload}) => {
            if (payload && payload.hasOwnProperty('city')) state.location = payload.city
        })
        builder.addCase(locationFinder.rejected, (state, action) => {
            state.location = "New York"
        })
    },
})

export const { updateCity } = navbarSlice.actions;

export default navbarSlice.reducer
