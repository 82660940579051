import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetCitiesAPI, GetCurrenciesAPI } from '../api/selectOptions';

interface selectOptionsState {
    isGetCitiesSuccess: boolean,
    isGetCitiesFetching: boolean,
    isGetCurrenciesSuccess: boolean,
    isGetCurrenciesFetching: boolean,
    errorCitiesLogMessage: string | null,
    errorCurrenciesLogMessage: string | null,
    citySelectOption: [] | [
      {
        value: '',
        label: '',
      }
    ]
    currencySelectOption: [] | [
      {
        value: '',
        label: '',
      }
    ]
}

const initialState: selectOptionsState = {
    isGetCitiesSuccess: false,
    isGetCitiesFetching: false,
    isGetCurrenciesSuccess: false,
    isGetCurrenciesFetching: false,
    errorCitiesLogMessage: "",
    errorCurrenciesLogMessage: "",
    citySelectOption: [],
    currencySelectOption: []
}


export const getAllCities = createAsyncThunk(
    'selectOptions/cities',
    async (cities: string, thunkAPI) => {
        const response = await GetCitiesAPI()
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.cities
    }
)

export const getAllCurrencies = createAsyncThunk(
    'selectOptions/currencies',
    async (currencies: string, thunkAPI) => {
        const response = await GetCurrenciesAPI()
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.currencies
    }
)

export const selectOptionsSlice = createSlice({
    name: 'selectOptions',
    initialState,
    reducers: {
        clearSelectOptionState: (state) => {
            state.isGetCitiesSuccess = false;
            state.isGetCitiesFetching = false;
            state.isGetCurrenciesSuccess = false;
            state.isGetCurrenciesFetching = false;
            state.errorCitiesLogMessage = "";
            state.errorCurrenciesLogMessage = "";
            state.citySelectOption = [];
            state.currencySelectOption = [];
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCities.fulfilled, (state, {payload}) => {
            state.isGetCitiesFetching = false
            state.isGetCitiesSuccess = true
            state.citySelectOption = payload
        })
        builder.addCase(getAllCities.rejected, (state, action) => {

            if (action.payload) {
                state.errorCitiesLogMessage = action.payload as unknown as string
              } else {
                state.errorCitiesLogMessage = action.error.message!
              }
        })
        builder.addCase(getAllCities.pending, (state) => {
            state.isGetCitiesFetching = true
        })
        builder.addCase(getAllCurrencies.fulfilled, (state, {payload}) => {
            state.isGetCurrenciesFetching = false
            state.isGetCurrenciesSuccess = true
            state.currencySelectOption = payload
        })
        builder.addCase(getAllCurrencies.rejected, (state, action) => {

            if (action.payload) {
                state.errorCurrenciesLogMessage = action.payload as unknown as string
              } else {
                state.errorCurrenciesLogMessage = action.error.message!
              }
        })
        builder.addCase(getAllCurrencies.pending, (state) => {
            state.isGetCurrenciesFetching = true
        })
    },
})

export const { clearSelectOptionState } = selectOptionsSlice.actions;

export default selectOptionsSlice.reducer
