import axios from 'axios';

/** Create video api */

export interface CreateVideoProps {
  description: string | null,
  video_url: string | null,
}

export const CreateVideoAPI = async ( data: CreateVideoProps) => {
  console.log("data: ", data)
  const url = `${process.env.REACT_APP_BASE_URL}/video/create`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'POST',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: data
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            console.log("create video error response: ", err.response)
            return err.response
          } else if (err.request) {
            console.log("create video axios request", err.request)
          } else {
            console.log("create video axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("create video response error catch: ", error.response)
    }
  }
}


export interface EditVideoProps {
  description: string | null,
  video_url: string | null,
  videoId: string | null
}

export const EditVideoAPI = async ( data: EditVideoProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/video/edit`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'PUT',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: data
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("edit video axios request", err.request)
          } else {
            console.log("edit video axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("edit video response error catch: ", error.response)
    }
  }
}


export interface DeleteVideoProps {
  videoId: string;
}

export const DeleteVideoAPI = async ( videoId: DeleteVideoProps) => {
  const url = `${process.env.REACT_APP_BASE_URL}/video/delete`
  const token = localStorage.getItem('token')
  if (!token) return { status: 'Failed', message: 'Token not found'}
  try {
    return axios({
      method: 'DELETE',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      data: {videoId}
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("delete video axios request", err.request)
          } else {
            console.log("delete video axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("delete video response error catch: ", error.response)
    }
  }
}


export const GetVideosAPI = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/video/all`

  try {
    return axios({
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => res)
      .catch(err =>
        {if (err.response) {
            return err.response
          } else if (err.request) {
            console.log("GET all video axios request", err.request)
          } else {
            console.log("GEET all video axios error something else", err.request)
          }
        }
      )
  } catch (error: any) {
    if (error.response) {
      console.log("GET all video response error catch: ", error.response)
    }
  }
}
