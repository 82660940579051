import { configureStore } from '@reduxjs/toolkit'
import registrationReducer from './../features/registrationSlice'
import addPasswordReducer from './../features/addPasswordSlice'
import loginReducer from './../features/loginSlice'
import resetPasswordReducer from './../features/resetPasswordSlice'
import requestPasswordReducer from './../features/requestPasswordSlice'
import createPostServiceReducer from './../features/postServiceSlice'
import ratingReducer from './../features/ratingSlice'
import profileReducer from './../features/profileSlice'
import dashboardReducer from './../features/dashboardSlice'
import languageReducer from './../features/languageSlice'
import navbarReducer from './../features/navbarSlice'
import changePasswordReducer from './../features/changePasswordSlice'
import blogReducer from './../features/blogSlice'
import homeReducer from './../features/homeSlice'
import reportReducer from './../features/reportSlice'
import adminReducer from './../features/adminSlice'
import videoReducer from './../features/videoSlice'
import selectOptionsReducer from './../features/selectOptionsSlice'
import configReducer from './../features/configSlice'

export const store = configureStore({
    reducer: {
        register: registrationReducer,
        addPassword: addPasswordReducer,
        login: loginReducer,
        resetPassword: resetPasswordReducer,
        resetPasswordRequest: requestPasswordReducer,
        createPostService: createPostServiceReducer,
        rating: ratingReducer,
        profile: profileReducer,
        dashboard: dashboardReducer,
        language: languageReducer,
        navbar: navbarReducer,
        changePassword: changePasswordReducer,
        blog: blogReducer,
        home: homeReducer,
        report: reportReducer,
        admin: adminReducer,
        video: videoReducer,
        selectOptions: selectOptionsReducer,
        config: configReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
