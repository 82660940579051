import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetConfigAPI, EditConfigShowPostAPI } from '../api/config';

interface configState {
    isGetSuccess: boolean,
    isGetFetching: boolean,
    errorLogMessage: string | null,
    searchAllPost: string | null,
    errorConfigEditMessage: string | null,
    configurations: {
      _id: string,
      show_post: string,
    },
}

const initialState: configState = {
    isGetSuccess: false,
    isGetFetching: false,
    errorLogMessage: "",
    searchAllPost: "",
    errorConfigEditMessage: "",
    configurations: {
      _id: '',
      show_post: '',
    },
}


export const getConfig = createAsyncThunk(
    'config/getter',
    async (config: string, thunkAPI) => {
        const response = await GetConfigAPI()
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.config
    }
)


interface EditConfigValidationErrors {
    errorConfigEditMessage: string | null
}

export const editConfig = createAsyncThunk<
    any,
    { configId: string | null, show_post: string | null},
    { rejectValue: EditConfigValidationErrors }
>(
    'config/edit',
    async (formData, thunkAPI) => {
        const response = await EditConfigShowPostAPI(formData)
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return response.data.config
    }
)

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setSearchAllPost: (state, {payload}) => {
          state.searchAllPost = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfig.fulfilled, (state, {payload}) => {
            state.isGetFetching = false
            state.isGetSuccess = true
            state.configurations = payload[0]
        })
        builder.addCase(getConfig.rejected, (state, action) => {

            if (action.payload) {
                state.errorLogMessage = action.payload as unknown as string
              } else {
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(getConfig.pending, (state) => {
            state.isGetFetching = true
        })

        builder.addCase(editConfig.fulfilled, (state, {payload}) => {
            state.isGetFetching = false
            state.isGetSuccess = true
            if(payload !== undefined && payload.hasOwnProperty('_id')) state.configurations = payload
        })
        builder.addCase(editConfig.rejected, (state, action) => {

            if (action.payload) {
                state.errorLogMessage = action.payload as unknown as string
              } else {
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(editConfig.pending, (state) => {
            state.isGetFetching = true
        })
    },
})

export const { setSearchAllPost } = configSlice.actions;
export default configSlice.reducer
