import { createSlice } from '@reduxjs/toolkit'


interface offreqState {
  value: string;
  showHelpTab: string;
  searchAllPost: string;
}

const initialState: offreqState = {
    value: '',
    showHelpTab: 'GETTING STARTED',
    searchAllPost: '',
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setRequestOffer: (state, {payload}) => {
            state.value = payload
          },
        setHelpTab: (state, {payload}) => {
            state.showHelpTab = payload
          },
        setSearchAllPost: (state, {payload}) => {
            state.searchAllPost = payload
          },
    },
})

export const { setRequestOffer, setHelpTab, setSearchAllPost } = homeSlice.actions;

export default homeSlice.reducer
