import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetBlogsAPI } from '../api/blog';

interface blogState {
    isGetSuccess: boolean,
    isGetFetching: boolean,
    errorLogMessage: string | null,
    selectedBlog: {
      _id: '',
      title: '',
      content: '',
      description: ''
    },
    allBlogs: [] | [
      {
        _id: '',
        title: '',
        content: '',
        description: ''
      }
    ]
}

const initialState: blogState = {
    isGetSuccess: false,
    isGetFetching: false,
    errorLogMessage: "",
    selectedBlog: {
      _id: '',
      title: '',
      content: '',
      description: ''
    },
    allBlogs: []
}


export const getAllBlogs = createAsyncThunk(
    'blog/getter',
    async (blog: string, thunkAPI) => {
        const response = await GetBlogsAPI()
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.blog
    }
)

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        selectedBlog: (state, {payload}) => {
            state.selectedBlog = payload
          },
        clearSelectedBlog: (state) => {
            state.selectedBlog = {
              _id: '',
              title: '',
              content: '',
              description: ''
            }
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllBlogs.fulfilled, (state, {payload}) => {
            state.isGetFetching = false
            state.isGetSuccess = true
            state.allBlogs = payload
        })
        builder.addCase(getAllBlogs.rejected, (state, action) => {

            if (action.payload) {
                state.errorLogMessage = action.payload as unknown as string
              } else {
                state.errorLogMessage = action.error.message!
              }
        })
        builder.addCase(getAllBlogs.pending, (state) => {
            state.isGetFetching = true
        })
    },
})

export const { selectedBlog, clearSelectedBlog } = blogSlice.actions;

export default blogSlice.reducer
