import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetVideosAPI, CreateVideoAPI, EditVideoAPI, DeleteVideoAPI } from '../api/video';

interface videoState {
    isGetVideosSuccess: boolean,
    isGetVideosFetching: boolean,
    isCreateVideoRequesting: boolean,
    isCreateVideoSuccess: boolean,
    isEditVideoRequesting: boolean,
    isEditVideoSuccess: boolean,
    isDeleteVideoRequesting: boolean,
    isDeleteVideoSuccess: boolean,
    errorVideoLogMessage: string | null,
    errorVideoCreateMessage: string | null,
    errorVideoEditMessage: string | null,
    errorVideoDeleteMessage: string | null,
    allVideos: [] | [
      {
        _id: '',
        description: '',
        video_url: '',
      }
    ],
    selectedVideo: {
      _id: '',
      video_url: '',
      description: ''
    },
}

const initialState: videoState = {
    isGetVideosSuccess: false,
    isGetVideosFetching: false,
    isCreateVideoRequesting: false,
    isCreateVideoSuccess: false,
    isEditVideoRequesting: false,
    isEditVideoSuccess: false,
    isDeleteVideoRequesting: false,
    isDeleteVideoSuccess: false,
    errorVideoLogMessage: "",
    errorVideoCreateMessage: "",
    errorVideoEditMessage: "",
    errorVideoDeleteMessage: "",
    allVideos: [],
    selectedVideo: {
      _id: '',
      video_url: '',
      description: ''
    },
}


export const getAllVideos = createAsyncThunk(
    'video/get_all',
    async (video: string, thunkAPI) => {
        const response = await GetVideosAPI()
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.videos
    }
)

interface CreateVideoValidationErrors {
    errorVideoCreateMessage: string | null
}

export const createVideo = createAsyncThunk<
    void,
    { description: string | null, video_url: string | null},
    { rejectValue: CreateVideoValidationErrors }
>(
    'video/create',
    async (formData, thunkAPI) => {
        const response = await CreateVideoAPI(formData)
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data
    }
)

interface EditVideoValidationErrors {
    errorVideoEditMessage: string | null
}

export const editVideo = createAsyncThunk<
    void,
    { videoId: string | null, description: string | null, video_url: string | null},
    { rejectValue: EditVideoValidationErrors }
>(
    'video/edit',
    async (formData, thunkAPI) => {
      console.log("edit video slice")
        const response = await EditVideoAPI(formData)
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data.video
    }
)

interface DeleteVideoValidationErrors {
    errorVideoDeleteMessage: string | null
}

export const deleteVideo = createAsyncThunk<
      void,
      { videoId: string,},
      { rejectValue: DeleteVideoValidationErrors }
    >(
    'video/delete',
    async (formData, thunkAPI) => {
      console.log("delete video formdata: ", formData)
        const response = await DeleteVideoAPI(formData)
        console.log("delete video: ", response)
        if (response.status !== 200) {
          if (response.data && response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(response.data.message)
          else return thunkAPI.rejectWithValue(response.data)
        }
        return await response.data
    }
)

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        selectedVideo: (state, {payload}) => {
          state.selectedVideo = payload
        },
        clearVideoStates: (state) => {
          state.isGetVideosSuccess = false;
          state.isGetVideosFetching = false;
          state.isCreateVideoRequesting = false;
          state.isCreateVideoSuccess = false;
          state.isEditVideoRequesting = false;
          state.isEditVideoSuccess = false;
          state.isDeleteVideoRequesting = false;
          state.isDeleteVideoSuccess = false;
          state.errorVideoLogMessage = "";
          state.errorVideoCreateMessage = "";
          state.errorVideoEditMessage = "";
          state.errorVideoDeleteMessage = "";
          state.allVideos = [];
          state.selectedVideo = {
            _id: '',
            video_url: '',
            description: ''
          }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllVideos.fulfilled, (state, {payload}) => {
            state.isGetVideosFetching = false
            state.isGetVideosSuccess = true
            state.allVideos = payload
        })
        builder.addCase(getAllVideos.rejected, (state, action) => {

            if (action.payload) {
                state.errorVideoLogMessage = action.payload as unknown as string
              } else {
                state.errorVideoLogMessage = action.error.message!
              }
        })
        builder.addCase(getAllVideos.pending, (state) => {
            state.isGetVideosFetching = true
        })

        builder.addCase(createVideo.fulfilled, (state, {payload}) => {
            state.isCreateVideoSuccess = true
            state.isCreateVideoRequesting = false
        })
        builder.addCase(createVideo.rejected, (state, action) => {
            if (action.payload) {
              state.errorVideoCreateMessage = action.payload as unknown as string
            } else {
              state.errorVideoCreateMessage = action.error.message!
            }
        })
        builder.addCase(createVideo.pending, (state) => {
            state.isCreateVideoRequesting = true
        })

        builder.addCase(editVideo.fulfilled, (state, {payload}) => {
            state.isEditVideoSuccess = true
            state.isEditVideoRequesting = false
        })
        builder.addCase(editVideo.rejected, (state, action) => {
            if (action.payload) {
              state.errorVideoEditMessage = action.payload as unknown as string
            } else {
              state.errorVideoEditMessage = action.error.message!
            }
        })
        builder.addCase(editVideo.pending, (state) => {
            state.isEditVideoRequesting = true
        })

        builder.addCase(deleteVideo.fulfilled, (state, {payload}) => {
            state.isDeleteVideoSuccess = true
            state.isDeleteVideoRequesting = false
        })
        builder.addCase(deleteVideo.rejected, (state, action) => {
            if (action.payload) {
              state.errorVideoDeleteMessage = action.payload as unknown as string
            } else {
              state.errorVideoDeleteMessage = action.error.message!
            }
        })
        builder.addCase(deleteVideo.pending, (state) => {
            state.isDeleteVideoRequesting = true
        })
    },
})

export const { clearVideoStates, selectedVideo } = videoSlice.actions;

export default videoSlice.reducer
