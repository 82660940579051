import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CreatePostAPI, EditPostAPI } from './../api/post'

interface serviceCreateState {
    isServSuccess: boolean,
    isServFetching: boolean,
    isServEditSuccess: boolean,
    isServEditFetching: boolean,
    errorServMessage: string | null,
    serviceId: string,
    serviceEditId: string,
    singleList: {
        post_type: string,
        title: string,
        content: string,
        from_city: string,
        from_state: string,
        from_country: string,
        destination_city: string,
        destination_state: string,
        destination_country: string,
        accept_crypto: boolean,
        show_phone_number: boolean,
        phone_calls: boolean,
        text_sms: boolean,
        package_size_dimension: string,
        vehicle_type: string,
        available_seats: number,
        price_min: number,
        price_max: number,
        currency: string | null,
        show_price: boolean,
        date_departure: Date | null,
        expired: boolean,
        package_image: string | File,
        vehicle_image: string | File,
        status: string,
        created_date: Date | null,
        _id: string
    }
}

interface ValidationErrors {
    errorServMessage: string | null
}

const initialState: serviceCreateState = {
    isServSuccess: false,
    isServFetching: false,
    isServEditSuccess: false,
    isServEditFetching: false,
    errorServMessage: "",
    serviceId: "",
    serviceEditId: "",
    singleList: {
        post_type: '',
        title: '',
        content: '',
        from_city: '',
        from_state: '',
        from_country: '',
        destination_city: '',
        destination_state: '',
        destination_country: '',
        accept_crypto: false,
        show_phone_number: false,
        phone_calls: false,
        text_sms: false,
        package_size_dimension: '',
        vehicle_type: '',
        available_seats: 0,
        price_min: 0,
        price_max: 0,
        currency: '',
        show_price: false,
        date_departure: null,
        expired: false,
        package_image: '',
        vehicle_image: '',
        status: '',
        created_date: null,
        _id: ''
    }
}



export const serviceCreateUser = createAsyncThunk<
    any,
    {
        post_type: string,
        title: string,
        content: string,
        from_city: string,
        from_state: string,
        from_country: string,
        destination_city: string,
        destination_state: string,
        destination_country: string,
        accept_crypto: boolean,
        show_phone_number: boolean,
        phone_calls: boolean,
        text_sms: boolean,
        package_size_dimension: string,
        vehicle_type: string,
        available_seats: number,
        price_min: number,
        price_max: number,
        currency: string | null,
        show_price: boolean,
        date_departure: Date | null,
        expired: boolean,
        package_image: string | File,
        vehicle_image: string | File,
        status: string,
        _id: string
    },
    { rejectValue: ValidationErrors }
>(
    'user/create/service',
    async (formData, thunkAPI) => {
        const response = await CreatePostAPI(formData)
        if (response.status !== 200) {
          if (response.data.hasOwnProperty('message')) return thunkAPI.rejectWithValue(await response.data.message)
          else return thunkAPI.rejectWithValue(await response.data)
        }
        return await response.data
    }
)

export const serviceEditCreateUser = createAsyncThunk<
    any,
    {
        post_type: string,
        title: string,
        content: string,
        from_city: string,
        from_state: string,
        from_country: string,
        destination_city: string,
        destination_state: string,
        destination_country: string,
        accept_crypto: boolean,
        show_phone_number: boolean,
        phone_calls: boolean,
        text_sms: boolean,
        package_size_dimension: string,
        vehicle_type: string,
        available_seats: number,
        price_min: number,
        price_max: number,
        currency: string | null,
        show_price: boolean,
        date_departure: Date | null,
        expired: boolean,
        package_image: string | File,
        vehicle_image: string | File,
        status: string,
        _id: string
    },
    { rejectValue: ValidationErrors }
>(
    'user/edit/service',
    async (formData, thunkAPI) => {
        const response = await EditPostAPI(formData)
        if (response.status !== 200) {
            if (response.data.message) return thunkAPI.rejectWithValue(await response.data.message)
        }
        return await response.data
    }
)

export const postServiceSlice = createSlice({
    name: 'create service',
    initialState,
    reducers: {
        clearServState: (state) => {
            state.errorServMessage = '';
            state.isServSuccess = false;
            state.isServFetching = false;
            state.serviceId = '';
        },
        clearServiceEditId: (state) => {
          state.serviceEditId = ''
        },
        addSingleItem: (state, {payload}) => {
            state.singleList = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(serviceCreateUser.fulfilled, (state, { payload }) => {
            state.isServFetching = false
            state.isServSuccess = true
            state.serviceId = payload.post._id
        })
        builder.addCase(serviceCreateUser.rejected, (state, action) => {
            if (action.payload) {
                state.errorServMessage = action.payload as unknown as string
            } else {
                state.errorServMessage = action.error.message!
            }
            state.isServFetching = false
        })
        builder.addCase(serviceCreateUser.pending, (state) => {
            state.isServFetching = true
        })
        builder.addCase(serviceEditCreateUser.fulfilled, (state, {payload}) => {
            state.isServEditFetching = false
            state.isServEditSuccess = true
            state.serviceEditId = payload.results.body._id
        })
        builder.addCase(serviceEditCreateUser.rejected, (state, action) => {
            if (action.payload) {
                state.errorServMessage = action.payload as unknown as string
            } else {
                state.errorServMessage = action.error.message!
            }
            state.isServEditFetching = false
        })
        builder.addCase(serviceEditCreateUser.pending, (state) => {
            state.isServEditFetching = true
        })
    },
})

export const { clearServState, clearServiceEditId, addSingleItem } = postServiceSlice.actions;

export default postServiceSlice.reducer
