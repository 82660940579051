import axios from 'axios';

/** Admin home dashboard api */
export const GetAdminDashboardAPI = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/`
    const token = await localStorage.getItem('token')
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'GET',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("get admin dashboard axios request", err.request)
              return err.request
            } else {
              console.log("get admin dashboard axios error something else", err.request)
              return err.request
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        return error.response
        console.log("get admin dashboard response error catch: ", error.response)
      }
      return error
    }
}

/** Set user profile **/
export interface UserRoleProps {
  profileId: string;
  newRole: string;
}
export const ChangeUserRole = async ( data: UserRoleProps) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/user/change_role`
    const token = localStorage.getItem('token')
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'PUT',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
        data: data
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("Change user role post axios request", err.request)
              return { status: 'Failed', message: 'Please try again'}
            } else {
              console.log("Reporting post axios error something else", err.request)
              return { status: 'Failed', message: 'Please try again'}
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("Reporting post response error catch: ", error.response)
      }
    }
}


/** reactivate user profile **/
export interface ReactivateUserProps {
  userid: string;
}
export const ReactivateUserRole = async ( data: ReactivateUserProps) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/user/reactivate`
    const token = localStorage.getItem('token')
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'PUT',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
        data: data
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("Change user role post axios request", err.request)
              return { status: 'Failed', message: 'Please try again'}
            } else {
              console.log("Reporting post axios error something else", err.request)
              return { status: 'Failed', message: 'Please try again'}
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("Reporting post response error catch: ", error.response)
      }
    }
}


/** delete user profile **/
export interface DeleteUserProps {
  userid: string;
}
export const DeleteUser = async ( data: DeleteUserProps) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/user/delete`
    const token = localStorage.getItem('token')
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'DELETE',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
        data: data
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("Delete user axios request", err.request)
              return { status: 'Failed', message: 'Please try again'}
            } else {
              console.log("Delete user axios error something else", err.request)
              return { status: 'Failed', message: 'Please try again'}
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("Delete user response error catch: ", error.response)
      }
    }
}


/** delete post **/
export interface DeletePostProps {
  postIds: String[];
}
export const DeletePost = async ( data: DeletePostProps) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/posts/delete`
    const token = localStorage.getItem('token')
    if (!token) return { status: 'Failed', message: 'Token not found'}
    try {
      return axios({
        method: 'DELETE',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        },
        data: JSON.stringify(data)
      })
        .then(res => res)
        .catch(err =>
          {if (err.response) {
              return err.response
            } else if (err.request) {
              console.log("Delete post axios request", err.request)
              return { status: 'Failed', message: 'Please try again'}
            } else {
              console.log("Delete post axios error something else", err.request)
              return { status: 'Failed', message: 'Please try again'}
            }
          }
        )
    } catch (error: any) {
      if (error.response) {
        console.log("Delete post response error catch: ", error.response)
      }
    }
}
